import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeaderTextService {
  public title = new BehaviorSubject('');
  public url = new BehaviorSubject('');

  setTitle(title) {
    this.title.next(title);
  }

  setUrl(url) {
    this.url.next(url);
  }
}
